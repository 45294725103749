export type TSettingsValues = {
  checkIn: string;
  checkOut: string;
  userName: string;
  password?: string;
  buildNumber: string;
  updateTitle: string;
  updateMessage: string;
};

export const settingsIniValues: TSettingsValues = {
  checkIn: "",
  checkOut: "",
  userName: "",
  buildNumber: "",
  updateMessage: "",
  updateTitle: "",
};
