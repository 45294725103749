import { authUrls } from "./auth.url";
import { studentUrls } from "./student.url";
import { attendanceUrls } from "./attendance.url";
import { settingsUrl } from "./settings.url";
import { qrUserUrls } from "./qruser";

export const apiUrls = {
  ...authUrls,
  ...studentUrls,
  ...attendanceUrls,
  ...settingsUrl,
  ...qrUserUrls
};
